import React, { useState, useEffect } from "react";
import styles from "../css/CardCommunityProgress.module.css";
import data_program from "../data/data_program";
import badge1 from "../assets/img/badges/1_badge_rabbit.png";
import badge2 from "../assets/img/badges/2_badge_deer.png";
import badge3 from "../assets/img/badges/3_badge_wolf.png";
import badge4 from "../assets/img/badges/4_badge_horse.png";
import badge5 from "../assets/img/badges/5_badge_bear.png";
import badge6 from "../assets/img/badges/6_badge_gorilla.png";
import badge7 from "../assets/img/badges/7_badge_lion.png";
import logo from "../assets/img/t4a_logo_white.svg";
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';


const badges = [badge1, badge2, badge3, badge4, badge5, badge6, badge7];

function CardCommunityProgress({ data_user }) {

  const { user, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 5;
  const [sortOption, setSortOption] = useState("Latest");
  const [favoritesOnly, setFavoritesOnly] = useState(false);
  const [selectedLevel, setSelectedLevel] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [showVideo, setShowVideo] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);

  const closeVideo = () => {
    setShowVideo(false);
    setVideoUrl(null);
  };

  const [favorites, setFavorites] = useState({});

  useEffect(() => {
    // Reset the current page whenever the filtering criteria change
    setCurrentPage(1);
  }, [favoritesOnly, selectedLevel, searchTerm, sortOption]);

  useEffect(() => {
    if (isAuthenticated && user?.username) {
      const userFavoritesArray = data_user.find((u) => u.username === user.username)?.favorites || [];
      const userFavoritesObject = userFavoritesArray.reduce((acc, fav) => {
        acc[fav] = true; // Convert array to object with true values
        return acc;
      }, {});
      setFavorites(userFavoritesObject);
    } else {
      const storedFavorites = JSON.parse(sessionStorage.getItem("favorites")) || {};
      setFavorites(storedFavorites);
    }
  }, [isAuthenticated, user?.username]);

  const getProgressBarColor = (percentage) => {
    if (percentage < 25) return "#E74C3C"; // Red
    if (percentage >= 25 && percentage < 50) return "#E67E22"; // Orange
    if (percentage >= 50 && percentage < 75) return "#F1C40F"; // Yellow
    if (percentage >= 75 && percentage < 100) return "#3498DB"; // Blue
    return "#2ECC71"; // Green
  };

  const getOverallJourneyProgress = (progressData) => {
    const totalProgress = data_program.reduce((acc, prog) => {
      const userProgress = progressData.find((item) => item.progress_code === prog.progress_code);
      const progressPercentage = userProgress
        ? Math.min((userProgress.best / prog.goal) * 100, 100)
        : 0;
      return acc + progressPercentage;
    }, 0);
    return Math.min(Math.floor((totalProgress / (data_program.length * 100)) * 100), 100);
  };

  const parseLevel = (progressCode) => {
    const level = parseInt(progressCode[1]); // Extract level number
    const subLevel = progressCode[2].charCodeAt(0) - "a".charCodeAt(0); // Convert 'a', 'b', 'c' to 0, 1, 2
    return { level, subLevel };
  };

  const compareLevels = (a, b) => {
    const levelA = parseLevel(a.progress_code);
    const levelB = parseLevel(b.progress_code);
    if (levelA.level === levelB.level) {
      return levelB.subLevel - levelA.subLevel; // Higher sub-levels first
    }
    return levelB.level - levelA.level; // Higher main levels first
  };

  const getTopThreeLevels = (progressData) => {
    const nonZeroLevels = progressData.filter((item) => item.best > 0);
    const sortedLevels = nonZeroLevels.sort(compareLevels);
    return sortedLevels.slice(0, 3).reverse(); // Take the top 3 and reverse to flip the order
  };

  const getHighestLevel = (progressData, overallProgress) => {
    // If overall progress is 100%, assign Level 7
    if (overallProgress === 100) {
      return "l7a"; // Return a dummy progress code representing Level 7
    }

    const nonZeroLevels = progressData.filter((item) => item.best > 0);
    const highestLevel = nonZeroLevels.sort(compareLevels)[0]; // Highest level in progress
    return highestLevel ? highestLevel.progress_code : null;
  };

  const getLevelColor = (progressCode) => {
    const levelNumber = parseInt(progressCode[1]); // Extract level number from "1a", "2b", etc.
    return levelNumber === 1 ? "black" : "white"; // Black for level 1, white for others
  };

  const calculateDurationInYears = (firstUpload, progressData) => {
    const firstDate = new Date(firstUpload);
    const lastDates = progressData
      .filter((item) => item.last_date)
      .map((item) => new Date(item.last_date));
    const latestDate = lastDates.length > 0 ? new Date(Math.max(...lastDates)) : firstDate;
    const durationInMs = latestDate - firstDate;
    const durationInYears = durationInMs / (1000 * 60 * 60 * 24 * 365.25); // Convert to years
    return durationInYears.toFixed(1); // Round to 1 decimal place
  };


  // Apply filtering and sorting
  const filteredUsers = data_user
    .filter((user) => {
      // Filter by favorites
      if (favoritesOnly) {
        return favorites[user.username] === true;
      }
      return true;
    })
    .filter((user) => {
      // Filter by selected level
      if (selectedLevel) {
        const overallProgress = getOverallJourneyProgress(user.progressData);
        const highestLevelCode = getHighestLevel(user.progressData, overallProgress);
        const userLevel = overallProgress === 100
          ? 7 // Assign Level 7 for 100% progress
          : highestLevelCode
            ? parseInt(highestLevelCode[1], 10)
            : null;
        return userLevel === selectedLevel;
      }
      return true; // Include all users if no level is selected
    })
    .filter((user) => {
      // Filter by search term
      if (searchTerm) {
        return user.username.toLowerCase().includes(searchTerm.toLowerCase());
      }
      return true;
    })
    .sort((a, b) => {
      if (sortOption === "Latest") {
        // Sort by latest progress date
        const latestA = new Date(
          Math.max(
            ...a.progressData
              .filter((p) => p.best_date)
              .map((p) => new Date(p.best_date))
          )
        );
        const latestB = new Date(
          Math.max(
            ...b.progressData
              .filter((p) => p.best_date)
              .map((p) => new Date(p.best_date))
          )
        );
        return latestB - latestA;
      } else if (sortOption === "Greatest") {
        // Sort by greatest overall journey progress percentage
        const totalProgressA = getOverallJourneyProgress(a.progressData);
        const totalProgressB = getOverallJourneyProgress(b.progressData);
        return totalProgressB - totalProgressA;
      }
      return 0;
    });


  const totalPages = Math.ceil(filteredUsers.length / usersPerPage);

  // Paginate the filtered users
  const paginatedUsers = filteredUsers.slice(
    (currentPage - 1) * usersPerPage,
    currentPage * usersPerPage
  );

  return (
    <div className={styles.card}>
      <div className={styles.filterRow}>
        <button
          className={sortOption === "Latest" ? styles.active : ""}
          onClick={() => setSortOption("Latest")}
        >
          Latest
        </button>
        <button
          className={sortOption === "Greatest" ? styles.active : ""}
          onClick={() => setSortOption("Greatest")}
        >
          Greatest
        </button>
        <button
          className={favoritesOnly ? styles.active : ""}
          onClick={() => setFavoritesOnly(!favoritesOnly)}
        >
          ❤
        </button>
        <input
          type="text"
          placeholder="username"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <div className={styles.dropdown}>
          <select
            value={selectedLevel || ""}
            onChange={(e) => {
              const level = e.target.value ? parseInt(e.target.value, 10) : null;
              setSelectedLevel(level);
            }}
          >
            <option value="">Level</option>
            {[...Array(7).keys()].map((level) => (
              <option key={level + 1} value={level + 1}>
                Level {level + 1}
              </option>
            ))}
          </select>
        </div>
      </div>
      <table className={styles.cardTable}>
        <thead>
          <tr>
            <th>User</th>
            <th>Level</th>
            <th>Journey</th>
            <th>Favorite</th>
            <th>Latest Progress</th>
          </tr>
        </thead>
        <tbody>
          {paginatedUsers.map((user) => {
            const overallProgress = getOverallJourneyProgress(user.progressData);
            const highestLevelCode = getHighestLevel(user.progressData, overallProgress);
            const highestLevelIndex = overallProgress === 100
              ? 6 // Index for Level 7 badge (7 - 1 = 6)
              : highestLevelCode
                ? parseInt(highestLevelCode[1]) - 1 // Determine the badge index
                : 0;

            const topThreeLevels = getTopThreeLevels(user.progressData);

            return (
              <tr key={user.username}>
                <td className={styles.user}>
                  <img
                    src={user.avatar}
                    alt={`${user.username} Avatar`}
                    className={styles.avatar}
                    onClick={() => {
                      navigate(`/progress`);
                      sessionStorage.setItem('selectedUser', JSON.stringify(user));
                    }}
                  />
                  <span className={styles.username}>{user.username}</span>
                </td>
                <td className={styles.level}>
                  <img
                    src={badges[highestLevelIndex]}
                    alt={`Level ${highestLevelIndex + 1}`}
                    className={styles.badge}
                  />
                  <span>Level {highestLevelIndex + 1}</span>
                </td>
                <td>
                  <div className={styles.progressBarContainer}>
                    <span className={styles.duration}>{calculateDurationInYears(user.first_upload, user.progressData)} yr</span>
                    <div className={styles.progressBar}>
                      <div
                        className={styles.progressFill}
                        style={{
                          width: `${overallProgress}%`,
                          backgroundColor: getProgressBarColor(overallProgress),
                        }}
                      ></div>
                    </div>
                  </div>
                  <span className={styles.progressPercentage}>{overallProgress}%</span>
                </td>
                <td className={styles.favorite}>
                  <span
                    className={`${styles.heartIcon} ${favorites[user.username] ? styles.favorited : styles.unfavorited}`}
                    onClick={() => {
                      const updatedFavorites = {
                        ...favorites,
                        [user.username]: !favorites[user.username], // Toggle favorite status
                      };
                      setFavorites(updatedFavorites);
                      sessionStorage.setItem("favorites", JSON.stringify(updatedFavorites));
                    }}
                  >
                    ❤
                  </span>
                </td>
                <td className={styles.levels}>
                  <div className={styles.levelsContainer}>
                    {topThreeLevels.map((level) => {
                      const shortLevel = level.progress_code.substring(1); // Remove the "l" prefix
                      const levelColor =
                        data_program.find((prog) => prog.progress_code === level.progress_code)?.color || "#ccc";

                      return (
                        <span
                          key={level.progress_code}
                          className={styles.levelCircle}
                          style={{
                            backgroundColor: levelColor,
                            color: getLevelColor(level.progress_code),
                            border: "1px solid black",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            if (level.best_url) {
                              window.open(level.best_url, "_blank"); // Open URL in a new tab
                            } else {
                              setVideoUrl(null); // Ensure no video URL is passed
                              setShowVideo(true); // Show the modal
                            }
                          }}
                        >
                          {shortLevel}
                        </span>
                      );
                    })}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className={styles.pagination}>
        <button
          className={styles.paginationButton}
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span className={styles.paginationInfo}>
          Page {currentPage} of {totalPages}
        </span>
        <button
          className={styles.paginationButton}
          onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
      {showVideo && (
        <div className={styles.videoPopup}>
          <div className={styles.videoCloseButton} onClick={closeVideo}>
            &times;
          </div>
          {videoUrl ? (
            <iframe
              width="100%"
              height="315"
              src={videoUrl}
              title="YouTube Video"
              frameBorder="0"
              allowFullScreen="true"
            ></iframe>
          ) : (
            <>
              <div className={styles.comingSoonMessage}>Coming soon!</div>
              <img src={logo} alt="thenics4all Logo" className={styles.logo} />
              <div className={styles.comingSoonMessageSubtitle}>
                Video not ready yet!
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );

}

export default CardCommunityProgress;