import defaultAvatar from '../assets/img/avatars/avatar_alien_white.png';
import avatar_zarrar from '../assets/img/avatars/avatar_zarrar.jpg';
import avatar_pashaMan from '../assets/img/avatars/avatar_pashaMan.jpg';
import avatar_akbar from '../assets/img/avatars/avatar_akbar.jpg';
import avatar_mooDeng from '../assets/img/avatars/avatar_mooDeng.jpg';
import avatar_dwight from '../assets/img/avatars/avatar_dwight.jpg';
import avatar_leaphorn from '../assets/img/avatars/avatar_leaphorn.jpg';
import avatar_balboa from '../assets/img/avatars/avatar_balboa.jpg';
import avatar_neo from '../assets/img/avatars/avatar_neo.jpg';
import avatar_groot from '../assets/img/avatars/avatar_groot.jpg';
import avatar_mrBean from '../assets/img/avatars/avatar_mrBean.jpg';
import avatar_babyYoda from '../assets/img/avatars/avatar_babyYoda.jpg';

const data_user = [
  {
    username: "zarrar",
    email: "zarrar@example.com",
    password: "pw",
    avatar: avatar_zarrar,
    favorites: ["pashaMan", "mooDeng", "babyYoda", "balboa"],
    unique: ["first_10_invited", "first_50_members"],
    first_upload: "2023-03-12",
    disclaimerAccepted: true,
    disclaimerAcceptedDate: "2024-01-15",
    progressData: [
      { progress_code: "l1a", best: 25, best_date: "2023-06-01", last: 25, last_date: "2023-06-01", best_url: null },
      { progress_code: "l1b", best: 60, best_date: "2023-06-15", last: 60, last_date: "2023-06-15", best_url: null },
      { progress_code: "l1c", best: 15, best_date: "2023-06-20", last: 15, last_date: "2023-06-20", best_url: null },
      { progress_code: "l2a", best: 10, best_date: "2023-07-10", last: 10, last_date: "2023-07-10", best_url: null },
      { progress_code: "l2b", best: 60, best_date: "2023-07-25", last: 60, last_date: "2023-07-25", best_url: null },
      { progress_code: "l2c", best: 10, best_date: "2023-08-05", last: 10, last_date: "2023-08-05", best_url: null },
      { progress_code: "l3a", best: 10, best_date: "2023-09-01", last: 7, last_date: "2023-09-01", best_url: null },
      { progress_code: "l3b", best: 60, best_date: "2023-10-01", last: 55, last_date: "2023-10-01", best_url: null },
      { progress_code: "l3c", best: 10, best_date: "2023-11-01", last: 9, last_date: "2023-11-01", best_url: null },
      { progress_code: "l4a", best: 10, best_date: "2024-12-01", last: 5, last_date: "2024-01-01", best_url: null },
      { progress_code: "l4b", best: 8, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l4c", best: 36, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l5a", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l5b", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l5c", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l6a", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l6b", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l6c", best: 0, best_date: null, last: 0, last_date: null, best_url: null }
    ],
  },
  {
    username: "pashaMan",
    email: "pashaman@example.com",
    password: "pw",
    avatar: avatar_pashaMan,
    favorites: ["zarrar", "mooDeng", "babyYoda", "dwight"],
    unique: ["first_20_community"],
    first_upload: "2023-04-15",
    disclaimerAccepted: true,
    disclaimerAcceptedDate: "2023-05-01",
    progressData: [
      { progress_code: "l1a", best: 25, best_date: "2023-06-05", last: 25, last_date: "2023-06-05", best_url: null },
      { progress_code: "l1b", best: 60, best_date: "2023-06-20", last: 60, last_date: "2023-06-20", best_url: null },
      { progress_code: "l1c", best: 20, best_date: "2023-07-10", last: 20, last_date: "2023-07-10", best_url: null },
      { progress_code: "l2a", best: 8, best_date: "2023-07-20", last: 8, last_date: "2023-07-20", best_url: null },
      { progress_code: "l2b", best: 45, best_date: "2024-11-05", last: 45, last_date: "2023-08-05", best_url: null },
      { progress_code: "l2c", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l3a", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l3b", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l3c", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l4a", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l4b", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l4c", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l5a", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l5b", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l5c", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l6a", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l6b", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l6c", best: 0, best_date: null, last: 0, last_date: null, best_url: null }
    ],
  },
  {
    username: "akbar",
    email: "akbar@example.com",
    password: "pw",
    avatar: avatar_akbar,
    favorites: ["balboa", "groot", "mrBean", "neo"],
    unique: ["first_50_members"],
    first_upload: "2023-08-10",
    disclaimerAccepted: true,
    disclaimerAcceptedDate: "2023-08-20",
    progressData: [
      { progress_code: "l1a", best: 25, best_date: "2023-09-01", last: 25, last_date: "2023-09-01", best_url: null },
      { progress_code: "l1b", best: 60, best_date: "2023-09-10", last: 60, last_date: "2023-09-10", best_url: null },
      { progress_code: "l1c", best: 15, best_date: "2023-09-20", last: 15, last_date: "2023-09-20", best_url: null },
      { progress_code: "l2a", best: 10, best_date: "2023-10-01", last: 10, last_date: "2023-10-01", best_url: null },
      { progress_code: "l2b", best: 60, best_date: "2023-10-15", last: 60, last_date: "2023-10-15", best_url: null },
      { progress_code: "l2c", best: 10, best_date: "2023-11-01", last: 10, last_date: "2023-11-01", best_url: null },
      { progress_code: "l3a", best: 8, best_date: "2023-12-01", last: 6, last_date: "2023-12-01", best_url: null },
      { progress_code: "l3b", best: 55, best_date: "2023-12-20", last: 50, last_date: "2023-12-20", best_url: null },
      { progress_code: "l3c", best: 9, best_date: "2024-01-10", last: 8, last_date: "2024-01-10", best_url: null },
      { progress_code: "l4a", best: 0, best_date: "2024-09-01", last: 5, last_date: "2024-02-01", best_url: null },
      { progress_code: "l4b", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l4c", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l5a", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l5b", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l5c", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l6a", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l6b", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l6c", best: 0, best_date: null, last: 0, last_date: null, best_url: null }
    ],
  },
  {
    username: "mooDeng",
    email: "moodeng@example.com",
    password: "pw",
    avatar: avatar_mooDeng,
    favorites: ["neo", "mrBean", "babyYoda"],
    unique: ["first_50_members"],
    first_upload: "2023-05-12",
    disclaimerAccepted: true,
    disclaimerAcceptedDate: "2023-06-01",
    progressData: [
      { progress_code: "l1a", best: 20, best_date: "2023-07-15", last: 20, last_date: "2023-07-15", best_url: null },
      { progress_code: "l1b", best: 50, best_date: "2023-08-01", last: 50, last_date: "2023-08-01", best_url: null },
      { progress_code: "l1c", best: 6, best_date: "2023-08-20", last: 10, last_date: "2023-08-20", best_url: null },
      { progress_code: "l2a", best: 0, best_date: "2023-09-15", last: 6, last_date: "2023-09-15", best_url: null },
      { progress_code: "l2b", best: 0, best_date: "2023-10-01", last: 50, last_date: "2023-10-01", best_url: null },
      { progress_code: "l2c", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l3a", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l3b", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l3c", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l4a", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l4b", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l4c", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l5a", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l5b", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l5c", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l6a", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l6b", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l6c", best: 0, best_date: null, last: 0, last_date: null, best_url: null }
    ],
  },
  {
    username: "dwight",
    email: "dwight@example.com",
    password: "pw",
    avatar: avatar_dwight,
    favorites: ["neo", "groot", "pashaMan"],
    unique: ["first_20_community", "first_50_members","first_10_invited"],
    first_upload: "2024-03-01",
    disclaimerAccepted: true,
    disclaimerAcceptedDate: "2024-03-10",
    progressData: [
      { progress_code: "l1a", best: 25, best_date: "2024-03-15", last: 25, last_date: "2024-03-15", best_url: null },
      { progress_code: "l1b", best: 60, best_date: "2024-03-20", last: 60, last_date: "2024-03-20", best_url: null },
      { progress_code: "l1c", best: 15, best_date: "2024-03-25", last: 15, last_date: "2024-03-25", best_url: null },
      { progress_code: "l2a", best: 10, best_date: "2024-04-01", last: 10, last_date: "2024-04-01", best_url: null },
      { progress_code: "l2b", best: 60, best_date: "2024-04-10", last: 50, last_date: "2024-04-10", best_url: null },
      { progress_code: "l2c", best: 10, best_date: "2024-04-20", last: 9, last_date: "2024-04-20", best_url: null },
      { progress_code: "l3a", best: 10, best_date: "2024-05-01", last: 6, last_date: "2024-05-01", best_url: null },
      { progress_code: "l3b", best: 60, best_date: "2024-05-15", last: 30, last_date: "2024-05-15", best_url: null },
      { progress_code: "l3c", best: 10, best_date: "2024-05-30", last: 8, last_date: "2024-05-30", best_url: null },
      { progress_code: "l4a", best: 10, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l4b", best: 10, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l4c", best: 30, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l5a", best: 8, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l5b", best: 8, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l5c", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l6a", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l6b", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l6c", best: 0, best_date: null, last: 0, last_date: null, best_url: null }
    ],
  },
  {
    username: "leaphorn",
    email: "leaphorn@example.com",
    password: "pw",
    avatar: avatar_leaphorn,
    favorites: ["pashaMan", "dwight", "babyYoda"],
    unique: ["first_50_members"],
    first_upload: "2024-02-01",
    disclaimerAccepted: true,
    disclaimerAcceptedDate: "2024-02-05",
    progressData: [
      { progress_code: "l1a", best: 25, best_date: "2024-02-15", last: 25, last_date: "2024-02-15", best_url: null },
      { progress_code: "l1b", best: 60, best_date: "2024-02-25", last: 60, last_date: "2024-02-25", best_url: null },
      { progress_code: "l1c", best: 15, best_date: "2024-03-05", last: 15, last_date: "2024-03-05", best_url: null },
      { progress_code: "l2a", best: 10, best_date: "2024-03-15", last: 10, last_date: "2024-03-15", best_url: null },
      { progress_code: "l2b", best: 60, best_date: "2024-04-01", last: 50, last_date: "2024-04-01", best_url: null },
      { progress_code: "l2c", best: 10, best_date: "2024-04-20", last: 7, last_date: "2024-04-20", best_url: null },
      { progress_code: "l3a", best: 10, best_date: "2024-05-01", last: 4, last_date: "2024-05-01", best_url: null },
      { progress_code: "l3b", best: 60, best_date: "2024-05-15", last: 25, last_date: "2024-05-15", best_url: null },
      { progress_code: "l3c", best: 10, best_date: "2024-06-01", last: 3, last_date: "2024-06-01", best_url: null },
      { progress_code: "l4a", best: 10, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l4b", best: 10, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l4c", best: 30, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l5a", best: 3, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l5b", best: 2, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l5c", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l6a", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l6b", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l6c", best: 0, best_date: null, last: 0, last_date: null, best_url: null }
    ],
  },
  {
    username: "balboa",
    email: "balboa@example.com",
    password: "pw",
    avatar: avatar_balboa,
    favorites: ["leaphorn", "mooDeng", "babyYoda"],
    unique: ["first_20_community"],
    first_upload: "2023-09-15",
    disclaimerAccepted: true,
    disclaimerAcceptedDate: "2023-09-20",
    progressData: [
      { progress_code: "l1a", best: 25, best_date: "2023-09-20", last: 20, last_date: "2023-09-20", best_url: null },
      { progress_code: "l1b", best: 60, best_date: "2023-09-25", last: 50, last_date: "2023-09-25", best_url: null },
      { progress_code: "l1c", best: 15, best_date: "2023-10-01", last: 10, last_date: "2023-10-01", best_url: null },
      { progress_code: "l2a", best: 10, best_date: "2023-10-15", last: 6, last_date: "2023-10-15", best_url: null },
      { progress_code: "l2b", best: 60, best_date: "2023-10-25", last: 35, last_date: "2023-10-25", best_url: null },
      { progress_code: "l2c", best: 10, best_date: "2023-11-05", last: 8, last_date: "2023-11-05", best_url: null },
      { progress_code: "l3a", best: 10, best_date: "2023-11-15", last: 3, last_date: "2023-11-15", best_url: null },
      { progress_code: "l3b", best: 60, best_date: "2023-12-01", last: 20, last_date: "2023-12-01", best_url: null },
      { progress_code: "l3c", best: 5, best_date: "2023-12-15", last: 4, last_date: "2023-12-15", best_url: null },
      { progress_code: "l4a", best: 3, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l4b", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l4c", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l5a", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l5b", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l5c", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l6a", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l6b", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l6c", best: 0, best_date: null, last: 0, last_date: null, best_url: null }
    ],
  },
  {
    username: "neo",
    email: "neo@example.com",
    password: "pw",
    avatar: avatar_neo,
    favorites: ["dwight", "mrBean", "balboa"],
    unique: ["first_20_community"],
    first_upload: "2023-12-15",
    disclaimerAccepted: true,
    disclaimerAcceptedDate: "2023-12-20",
    progressData: [
      { progress_code: "l1a", best: 25, best_date: "2023-12-25", last: 22, last_date: "2023-12-25", best_url: null },
      { progress_code: "l1b", best: 60, best_date: "2024-01-05", last: 55, last_date: "2024-01-05", best_url: null },
      { progress_code: "l1c", best: 15, best_date: "2024-01-15", last: 10, last_date: "2024-01-15", best_url: null },
      { progress_code: "l2a", best: 10, best_date: "2024-02-01", last: 5, last_date: "2024-02-01", best_url: null },
      { progress_code: "l2b", best: 60, best_date: "2024-02-15", last: 35, last_date: "2024-02-15", best_url: null },
      { progress_code: "l2c", best: 10, best_date: "2024-03-01", last: 8, last_date: "2024-03-01", best_url: null },
      { progress_code: "l3a", best: 10, best_date: "2024-03-15", last: 2, last_date: "2024-03-15", best_url: null },
      { progress_code: "l3b", best: 60, best_date: "2024-03-30", last: 18, last_date: "2024-03-30", best_url: null },
      { progress_code: "l3c", best: 10, best_date: "2024-04-15", last: 4, last_date: "2024-04-15", best_url: null },
      { progress_code: "l4a", best: 10, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l4b", best: 10, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l4c", best: 30, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l5a", best: 10, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l5b", best: 10, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l5c", best: 10, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l6a", best: 10, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l6b", best: 6, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l6c", best: 0, best_date: null, last: 0, last_date: null, best_url: null }
    ],
  },
  {
    username: "groot",
    email: "groot@example.com",
    password: "pw",
    avatar: avatar_groot,
    favorites: ["babyYoda", "mrBean", "mooDeng"],
    unique: ["first_50_members"],
    first_upload: "2024-01-10",
    disclaimerAccepted: true,
    disclaimerAcceptedDate: "2024-01-15",
    progressData: [
      { progress_code: "l1a", best: 25, best_date: "2024-01-15", last: 25, last_date: "2024-01-15", best_url: null },
      { progress_code: "l1b", best: 60, best_date: "2024-01-20", last: 60, last_date: "2024-01-20", best_url: null },
      { progress_code: "l1c", best: 15, best_date: "2024-01-25", last: 15, last_date: "2024-01-25", best_url: null },
      { progress_code: "l2a", best: 10, best_date: "2024-02-01", last: 10, last_date: "2024-02-01", best_url: null },
      { progress_code: "l2b", best: 60, best_date: "2024-02-10", last: 60, last_date: "2024-02-10", best_url: null },
      { progress_code: "l2c", best: 10, best_date: "2024-02-20", last: 10, last_date: "2024-02-20", best_url: null },
      { progress_code: "l3a", best: 10, best_date: "2024-03-01", last: 10, last_date: "2024-03-01", best_url: null },
      { progress_code: "l3b", best: 60, best_date: "2024-03-15", last: 60, last_date: "2024-03-15", best_url: null },
      { progress_code: "l3c", best: 10, best_date: "2024-03-30", last: 10, last_date: "2024-03-30", best_url: null },
      { progress_code: "l4a", best: 10, best_date: "2024-04-10", last: 10, last_date: "2024-04-10", best_url: null },
      { progress_code: "l4b", best: 10, best_date: "2024-04-20", last: 10, last_date: "2024-04-20", best_url: null },
      { progress_code: "l4c", best: 30, best_date: "2024-04-30", last: 30, last_date: "2024-04-30", best_url: null },
      { progress_code: "l5a", best: 10, best_date: "2024-05-10", last: 10, last_date: "2024-05-10", best_url: null },
      { progress_code: "l5b", best: 10, best_date: "2024-05-20", last: 10, last_date: "2024-05-20", best_url: null },
      { progress_code: "l5c", best: 10, best_date: "2024-05-30", last: 10, last_date: "2024-05-30", best_url: null },
      { progress_code: "l6a", best: 10, best_date: "2024-06-10", last: 20, last_date: "2024-06-10", best_url: null },
      { progress_code: "l6b", best: 10, best_date: "2024-06-20", last: 10, last_date: "2024-06-20", best_url: null },
      { progress_code: "l6c", best: 5, best_date: "2024-06-30", last: 5, last_date: "2024-06-30", best_url: null }
    ],
  },
  {
    username: "mrBean",
    email: "mrBean@example.com",
    password: "pw",
    avatar: avatar_mrBean,
    favorites: ["groot", "babyYoda", "neo"],
    unique: ["first_10_invited"],
    first_upload: "2024-02-01",
    disclaimerAccepted: true,
    disclaimerAcceptedDate: "2024-02-05",
    progressData: [
      { progress_code: "l1a", best: 23, best_date: "2024-02-10", last: 25, last_date: "2024-02-10", best_url: null },
      { progress_code: "l1b", best: 32, best_date: "2024-02-20", last: 60, last_date: "2024-02-20", best_url: null },
      { progress_code: "l1c", best: 12, best_date: "2024-03-01", last: 15, last_date: "2024-03-01", best_url: null },
      { progress_code: "l2a", best: 0, best_date: "2024-03-10", last: 10, last_date: "2024-03-10", best_url: null },
      { progress_code: "l2b", best: 0, best_date: "2024-03-20", last: 60, last_date: "2024-03-20", best_url: null },
      { progress_code: "l2c", best: 0, best_date: "2024-03-30", last: 10, last_date: "2024-03-30", best_url: null },
      { progress_code: "l3a", best: 0, best_date: "2024-04-10", last: 10, last_date: "2024-04-10", best_url: null },
      { progress_code: "l3b", best: 0, best_date: "2024-04-20", last: 60, last_date: "2024-04-20", best_url: null },
      { progress_code: "l3c", best: 0, best_date: "2024-04-30", last: 10, last_date: "2024-04-30", best_url: null },
      { progress_code: "l4a", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l4b", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l4c", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l5a", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l5b", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l5c", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l6a", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l6b", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l6c", best: 0, best_date: null, last: 0, last_date: null, best_url: null }
    ],
  },
  {
    username: "babyYoda",
    email: "babyYoda@example.com",
    password: "pw",
    avatar: avatar_babyYoda,
    favorites: ["mrBean", "groot", "zarrar"],
    unique: ["first_20_community"],
    first_upload: "2024-05-01",
    disclaimerAccepted: true,
    disclaimerAcceptedDate: "2024-05-05",
    progressData: [
      { progress_code: "l1a", best: 25, best_date: "2024-05-10", last: 25, last_date: "2024-05-10", best_url: null },
      { progress_code: "l1b", best: 60, best_date: "2024-05-20", last: 60, last_date: "2024-05-20", best_url: null },
      { progress_code: "l1c", best: 15, best_date: "2024-05-30", last: 15, last_date: "2024-05-30", best_url: null },
      { progress_code: "l2a", best: 10, best_date: "2024-06-10", last: 10, last_date: "2024-06-10", best_url: null },
      { progress_code: "l2b", best: 34, best_date: "2024-06-20", last: 60, last_date: "2024-06-20", best_url: null },
      { progress_code: "l2c", best: 8, best_date: "2024-06-30", last: 10, last_date: "2024-06-30", best_url: null },
      { progress_code: "l3a", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l3b", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l3c", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l4a", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l4b", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l4c", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l5a", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l5b", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l5c", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l6a", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l6b", best: 0, best_date: null, last: 0, last_date: null, best_url: null },
      { progress_code: "l6c", best: 0, best_date: null, last: 0, last_date: null, best_url: null }
    ],
  }
];

export default data_user;
