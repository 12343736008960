import { React } from "react";
import { Container } from "react-bootstrap";
import CardCommunityProgress from "./CardCommunityProgress";
import "../css/Home.css";
import "../css/Community.css";
import "../css/ComingSoon.css";
import data_user from '../data/data_user';
import { Helmet } from "react-helmet";

function Community() {

  const video_row_5 =
    "https://t4abucket225336-staging.s3.us-east-2.amazonaws.com/video_pullups.mp4";

  return (
    <div className="page">
      <Helmet>
        <title>thenics4all</title>
        <meta
          name="description"
          content="Step-by-step Handstand pushup progressions, training and tutorials."
        />
        <meta
          name="keywords"
          content="handstand, handstand pushup, hspu, calisthenics, progression, tutorial"
        />
      </Helmet>
      <Container fluid>
        <div className="home-row">
          <video
            autoPlay
            loop
            muted
            playsInline
            className="home-row-video-background"
          >
            <source src={video_row_5} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <h1>
            <a className="home-link" href="/progress">
              Community
            </a>
          </h1>
          <h2 className="home-row-subtitle">One Goal. Many Paths.</h2>
          <CardCommunityProgress data_user={data_user} openVideo={(url) => {
            if (url) {
              window.open(url, "_blank");
            }
          }} />
        </div>
      </Container>
    </div>
  );
}
export default Community;