import React from "react";
import "../css/ComingSoon.css";
import logo from "../assets/img/t4a_logo_white.svg";

function LoginTemp() {
  return (
    <div className="page">
      <div className="coming-soon-message">Registration opens shortly...</div>
      <img src={logo} alt="thenics4all Logo" className="coming-soon-logo" />
      <div className="coming-soon-subtitle">
        Stay tuned!
      </div>
    </div>
  );
}

export default LoginTemp;
