import React, { useRef, useState, useEffect } from "react";
import styles from "../css/CardHSPUProgressProfile.module.css";
import { BsFillPlayBtnFill, BsPlusCircleFill } from "react-icons/bs";
import data_program from "../data/data_program";
import { FaLock, FaUnlockAlt } from "react-icons/fa";
import logo from "../assets/img/t4a_logo_white.svg";

function CardHSPUProgressProfile({ data_user, isOwnProfile, onUpdateProgress }) {
  const cardRef = useRef(null);
  const [showVideo, setShowVideo] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [showProgressModal, setShowProgressModal] = useState(false);
  const [selectedLevel, setSelectedLevel] = useState(null);
  const [enteredProgress, setEnteredProgress] = useState("");
  const [uploadedVideo, setUploadedVideo] = useState(null);
  const isSaveEnabled = enteredProgress && uploadedVideo;

  const handleVideoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setUploadedVideo(file);
      console.log("Video uploaded:", file.name); // For debugging purposes
    }
  };


  const openProgressModal = (level) => {
    setSelectedLevel(level);
    setShowProgressModal(true);
  };

  const closeProgressModal = () => {
    setShowProgressModal(false);
    setSelectedLevel(null);
    setEnteredProgress("");
  };

  const handleSaveProgress = () => {
    if (enteredProgress && selectedLevel) {
      const user = data_user[0]; // Assuming single user for now
      const progressItem = user.progressData.find(
        (p) => p.progress_code === selectedLevel
      );
      if (progressItem) {
        progressItem.best = Math.max(progressItem.best, parseInt(enteredProgress, 10));
        progressItem.last = parseInt(enteredProgress, 10);
        progressItem.last_date = new Date().toISOString().split('T')[0];
        if (uploadedVideo) {
          progressItem.best_url = URL.createObjectURL(uploadedVideo);
        }

        onUpdateProgress(data_user[0].progressData);
      }
      closeProgressModal();
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (showVideo && cardRef.current && !cardRef.current.contains(event.target)) {
        closeVideo();
      }
    };

    window.addEventListener("click", handleOutsideClick);
    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [showVideo]);

  const openVideo = (url) => {
    setVideoUrl(url || "");
    setShowVideo(true);
  };

  const closeVideo = () => {
    setShowVideo(false);
    setVideoUrl("");
  };

  // Determine the next level that should show the unlock icon
  const determineUnlockedLevel = () => {
    let highestSequentiallyCompletedLevel = -1;

    for (let level = 0; level < 7; level++) {
      const levelTasks = data_program.filter((data) => data.level === `Level ${level + 1}`);
      const levelComplete = levelTasks.every((data) => {
        const userProgress = data_user[0].progressData.find(
          (item) => item.progress_code === data.progress_code
        );
        const progressPercentage = userProgress
          ? Math.min((userProgress.best / data.goal) * 100, 100)
          : 0;
        return progressPercentage >= 100;
      });

      if (levelComplete) {
        highestSequentiallyCompletedLevel = level;
      } else {
        break;
      }
    }

    const unlockedLevelIndex = highestSequentiallyCompletedLevel + 2;
    console.log(`Unlocked Level Index: ${unlockedLevelIndex}`);
    return unlockedLevelIndex;
  };

  const unlockedLevelIndex = determineUnlockedLevel();

  return (
    <div className={styles.card} ref={cardRef}>
      <table className={styles.cardTable}>
        <thead>
          <tr>
            <th className={styles.firstCol}>Progression</th>
            <th className={styles.secondCol}>Personal Best</th>
          </tr>
        </thead>
        <tbody>
          {data_program.map((data, dataIndex) => {
            const level = parseInt(data.progress_code.charAt(1), 10);
            console.log(`Row ${dataIndex + 1}: Level = ${level}, Unlocked Level Index = ${unlockedLevelIndex}`);

            return (
              <tr key={dataIndex} className={`${styles.level}-${level}`}>
                <td
                  className={`${styles.firstCol} ${styles.progressionCell}`}
                  style={{
                    background: data.color,
                    color: data.color === "white" ? "black" : "white",
                    textAlign: "left",
                  }}
                  onClick={() => openVideo(data.videoUrl)}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span>{data.progression}</span>
                    <button
                      className={styles.watchVideoButton}
                      style={{
                        color: data.color === "white" ? "black" : "white",
                        marginLeft: "auto",
                      }}
                    >
                      <BsFillPlayBtnFill className={styles.watchVideoIcon} />
                    </button>
                  </div>
                </td>

                {data_user.slice(0, 1).map((user, userIndex) => {
                  const userProgress = user.progressData.find(
                    (item) => item.progress_code === data.progress_code
                  );
                  const progressPercentage = userProgress
                    ? Math.min((userProgress.best / data.goal) * 100, 100)
                    : 0;

                  return (
                    <React.Fragment key={userIndex}>
                      <td
                        className={`${styles.secondCol}`}
                        style={{
                          background: data.color,
                          color: data.color === "white" ? "black" : "white",
                          width: "150px",
                        }}
                      >
                        <div
                          className={`${styles.progressBarContainer} ${isOwnProfile ? styles.withPlusIcon : styles.noPlusIcon
                            }`}
                        >
                          <div style={{ display: "flex", alignItems: "center" }}>
                            {userProgress && userProgress.best > 0 ? (
                              <div className={styles.progressBar}>
                                <span className={styles.progressPercentageLeft}>
                                  {userProgress.best}
                                </span>
                                <div
                                  className={styles.progressFill}
                                  style={{
                                    width: `${progressPercentage}%`,
                                    backgroundColor:
                                      progressPercentage < 25
                                        ? "#E74C3C"
                                        : progressPercentage >= 25 && progressPercentage < 50
                                          ? "#E67E22"
                                          : progressPercentage >= 50 && progressPercentage < 75
                                            ? "#F1C40F"
                                            : progressPercentage >= 75 && progressPercentage < 100
                                              ? "#3498DB"
                                              : "#2ECC71",
                                  }}
                                ></div>
                                <span className={styles.progressPercentageRight}>
                                  {`${progressPercentage.toFixed(0)}%`}
                                </span>
                              </div>
                            ) : (
                              <div className={styles.lockedIcon}>
                                {level === unlockedLevelIndex ? (
                                  <>
                                    <FaUnlockAlt className={styles.unlockedIcon} />
                                    {isOwnProfile && (
                                      <button
                                        className={styles.plusIconButton}
                                        style={{
                                          color: data.color === "white" ? "black" : "white",
                                        }}
                                        onClick={() => openProgressModal(data.progress_code)}
                                      >
                                        <BsPlusCircleFill className={styles.plusIconButton} />
                                      </button>
                                    )}
                                  </>
                                ) : (
                                  <FaLock style={{ color: "gray" }} />
                                )}
                              </div>
                            )}
                            {userProgress && userProgress.best > 0 && (
                              <>
                                <button
                                  className={styles.watchVideoButton}
                                  style={{
                                    color: data.color === "white" ? "black" : "white",
                                  }}
                                  onClick={() =>
                                    userProgress.best_url
                                      ? openVideo(userProgress.best_url)
                                      : openVideo(null)
                                  }
                                >
                                  <BsFillPlayBtnFill className={styles.watchVideoIcon} />
                                </button>
                                {isOwnProfile && (
                                  <button
                                    className={styles.plusIconButton}
                                    style={{
                                      color: data.color === "white" ? "black" : "white",
                                    }}
                                    onClick={() => openProgressModal(data.progress_code)}
                                  >
                                    <BsPlusCircleFill className={styles.plusIconButton} />
                                  </button>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </td>
                    </React.Fragment>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      {showProgressModal && (
        <div
          className={styles.progressModalWrapper}
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              closeProgressModal();
            }
          }}
        >
          <div className={styles.progressModal}>
            <div className={styles.modalContent}>
              <div className={styles.modalHeader}>
                <h3>{`Level ${data_program.find((item) => item.progress_code === selectedLevel)?.progression || ""}`}</h3>
              </div>
              <div className={styles.modalBody}>
                <label htmlFor="uploadVideo" className={styles.uploadVideoLabel}>
                  Update Progress & Upload Video Proof
                </label>
                <input
                  type="number"
                  value={enteredProgress}
                  onChange={(e) => setEnteredProgress(e.target.value)}
                  placeholder="Enter your progress"
                  className={styles.inputField}
                />
                <input
                  type="file"
                  id="uploadVideo"
                  accept="video/*"
                  className={styles.uploadVideoInput}
                  onChange={(e) => handleVideoUpload(e)}
                />
              </div>
              <div className={styles.modalFooter}>
                <button
                  onClick={handleSaveProgress}
                  className={styles.saveButton}
                  disabled={!isSaveEnabled} /* Disable button if conditions aren't met */
                >
                  Save
                </button>
                <button onClick={closeProgressModal} className={styles.cancelButton}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}



      {showVideo && (
        <div className={styles.videoPopup}>
          <div className={styles.videoCloseButton} onClick={closeVideo}>
            &times;
          </div>
          {videoUrl ? (
            <iframe
              width="100%"
              height="315"
              src={videoUrl}
              title="YouTube Video"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          ) : (
            <>
              <div className={styles.comingSoonMessage}>Coming soon!</div>
              <img src={logo} alt="thenics4all Logo" className={styles.logo} />
              <div className={styles.comingSoonMessageSubtitle}>
                Working on recording the video!
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default CardHSPUProgressProfile;
